<template>
<div class="dash-font">
  <header></header>
  <!-- 페이지시작 -->
  <section class="cover">
    <div class="cover_img_youth">
      <ul class="cover_psn_d">
        <li><span>Sample ID :</span> {{ sample.code }}<span>고객명 :</span> {{ sample.name }}<span>성별 :</span> {{ sample.sex }}</li>
        <li><span>발급일 :</span> {{ moment(new Date()).format('YYYY년 M월 D일') }} <span>발급처 :</span> AiBiotics<span>문의처 :</span> 070.7703.0380 </li>
      </ul>
    </div>
  </section>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="full">
  </section>
  <!-- 페이지끝 -->
  <div style="height: 150px"> </div>

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_microbiome_explain.png" alt="microbiome"><span class="miso"></span></h2>
  </div>
  <section>
    <ul class="microbiome_txt">
      <li>
        <div>
          <strong>Microbiome 확인 하는 이유</strong>
          <span>
          인간의 몸에는 인간과 공생하는 또 다른 생명들이 존재합니다.<br>
					바로 ‘미생물’입니다.<br>
					인체에 존재하는 미생물의 90% 이상이 장(Gut)에 존재하며, 이 미생물의 총 무게는1.5~2Kg 정도로 인간 몸무게의 2~4%를 차지할 정도로 많고 다양합니다.
					특히 장에 사는 미생물들은 인간의 질병 90%와 관련이 있고, 면역 기능 중 70%를 담당하고 있어 면역과 소화에 절대적인 역할을 하고 있습니다.<br>
					따라서 미생물이 인체에 미치는 영향은 매우 크기 때문에 제 2의 유전자인 장 미생물 분석이 필요합니다.
					</span>
        </div>
      </li>
      <li class="microtxt_adult">
        <div>
          <strong>Microbiome은 제2의 유전자</strong>
          <span>
					타고나기를 건강하게 태어난 사람이 있는가 하면, 약한 어린 시절을 극복하고 후천적으로 건강을 찾는 경우도 있습니다.<br>
					좋은 식습관과 운동 습관 그리고 긍정적인 태도가 건강을 유지하는 비결입니다. 장내 <br>미생물은 이런 생활 전반의 습관으로 만들어지고, 그런 습관들은 가정에서 형성되기 때문에 <br>제2의 유전자로 불립니다.
					성장기에 형성된 장 미생물 생태계는 면역과 성장에 아주 중요한 역할을 하게 됩니다.<br>
					어렵게 병을 고치기 보다 어릴 때부터 건강한 습관을 몸에 배도록 하는 것이 가장 좋습니다.
					좋은 장 환경을 만들어 주는 좋은 습관을 만들어 봅시다.
					</span>
        </div>
      </li>
      <li>
        <div>
          <strong>인체는 생태계</strong>
          <span>
					인간의 몸은 하나의 생태계이며 우주와도 같습니다. 수 십조의 미생물들이 인간의 몸에서 역할을 하며 공존하고 있습니다.
					보이지 않는 아주 복잡한 나름대로의 규칙과 질서가 있지만 그 관계가 너무 다양하고 복잡하기 때문에 우리는 모두를 이해할 수 없을 뿐입니다.<br>

					하지만 그 균형이 깨어지거나 결핍이 발생할 때 복원하는 방법을 찾기 위해 첨단 기술을 <br>이용한 장내 미생물의 균형을 측정합니다.
					또한 균형을 복원하는 방법을 생활 전반의 <br>균형에서부터 찾고 나에게 맞는 유산균과 음식을 찾아보고자 합니다.
					</span>
        </div>
      </li>
    </ul>
    <div class="tip_box txt_summary">
			<span>장내 미생물 검사에 의한 미생물 검출이 질병을 의미하지는 않습니다. 또한 생물 검출 실패는 질병을 일으키는 미생물의 존재를 배제하지
			않습니다. 이 검사에 의해 검출되지 않는 다른 유기체도 존재할 수 있습니다. 이 검사는 미생물 또는 그 항균 민감도를 확인하는 확립 된
			방법을 대체하지 않습니다. 건강 상태에 설명 된 박테리아와 결과는 가능한 문제의 징후만을 제공합니다. 이것은 진단이 아니며 그렇게
			간주 될 수 없습니다. 그러나 미래에 문제가 될 수 있는 가능성은 설명이 가능 할 수도 있습니다.</span>
    </div>
  </section>
  <div class="footer" style="margin-bottom:20px;">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span></span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="full">
    <ul class="index_list">
      <li></li>
      <li>
        <ol>
          <li>
            <span>01</span>
            <strong> Introduction</strong>
          </li>
          <li>
            <span>02</span>
            <strong> 종합 평가</strong>
          </li>
          <li>
            <span>03</span>
            <strong> Balance 평가</strong>
            <ul>
              <li>ㆍ3.1 Balance 1 : 비만</li>
              <li>ㆍ3.2 Balance 2 : 식성 /식이취향</li>
              <li>ㆍ3.3 Balance 3 : 운동</li>
              <li>ㆍ3.4 Balance 4 : 수면,음주흡연,감성</li>
              <li>ㆍ3.4 Balance 5 : 자가건강,가족력</li>
              <li>ㆍ3.5 Balance 6 : 장내 미생물</li>
            </ul>
          </li>
          <li>
            <span>04</span>
            <strong> 다양성</strong>
          </li>
          <li>
            <span>05</span>
            <strong> Phylum (문)분석</strong>
          </li>
          <li>
            <span>06</span>
            <strong> Entero Type (장유형)분석</strong>
          </li>
          <li>
            <span>07</span>
            <strong> 유산균 분석</strong>
          </li>
          <li>
            <span>08</span>
            <strong> 장, 면역 세부지표</strong>
          </li>
          <li>
            <span>09</span>
            <strong> Summary</strong>
          </li>
        </ol>
      </li>
    </ul>
  </section>
  <!-- 페이지끝 -->
  <div style="height: 100px"> </div>
  <!-- 페이지시작 -->
  <section class="header_none">
    <div class="star_graph">
      <v-chart :options="starChart" :initOptions="{
            width: 1100, height : 500 }" theme='shine'/>
    </div>
    <div class="balance_index">
      <ul>
        <li>AiB Health Balance Index</li>
        <li :class="bIndexClass"><span>B</span></li>
        <li>({{bIndexStr}})</li>
      </ul>
    </div>
    <div class="cmt_doctor">
      <strong>Comment</strong><br><br>
      <p v-html="sample.comment"></p>
    </div>
    <div class="tip_box">
      <div>
				<span>
				장 미생물의 분포는 질병과 생활 전반의 다양한 인자가 결합되어 나타나는 건강 Balance의 원인이자 결과입니다.
				다양한 <br>원인에 의하여 상호작용하여 나타납니다.<br>
				AiB는 다양한 인자를 고려하여 생활전반의 '균형' 을 회복하는데 도움을 드리고자 합니다.<br><br>

				A : 우수(&gt;8.5), B : 양호(6.5~8.5), C : 보통(4.5~6.5), D : 주의(3~3.5), F : 경고(&lt;3)
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span></span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="full">
    <table class="surveytb surveytb_1 surveytb_etc">
      <tbody>
      <colgroup>
        <col width="">
        <col width="15%">
        <col width="11%">
        <col width="11%">
        <col width="15%">
        <col width="11%">
        <col width="11%">
        <col width="11%">
      </colgroup>
      <tr class="cate">
        <td colspan="8">고객정보답변</td>
      </tr>
      <tr class="title">
        <td>Sample ID</td>
        <td>작성일</td>
        <td>이름</td>
        <td>나이</td>
        <td>생년월일</td>
        <td>신장</td>
        <td>체중</td>
        <td>BMI</td>
      </tr>
      <tr>
        <td>{{sample.code}}</td>
        <td>{{moment(new Date()).format('YYYY.MM.DD')}}</td>
        <td>{{survey.name}}</td>
        <td>{{survey.age}}</td>
        <td>{{survey.birthday_year+"."+survey.birthday_month+"."+survey.birthday_day}}</td>
        <td>{{survey.height}}cm</td>
        <td>{{survey.weight}}kg</td>
        <td>{{survey.bmi}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody>
      <tr class="cate">
        <td colspan="6">현재 함께 거주하는 가족 정보</td>
      </tr>
      <tr class="title">
        <td style="width: 16.6%">조부모</td>
        <td style="width: 16.6%">부모</td>
        <td style="width: 16.6%">형제</td>
        <td style="width: 16.6%">자녀</td>
        <td style="width: 16.6%">애완동물</td>
        <td style="width: 16.6%">총</td>
      </tr>
      <tr>
        <td>{{ survey.spouse_num }}</td>
        <td>{{ survey.parent_num }}</td>
        <td>{{ survey.brother_num }}</td>
        <td>{{ survey.children_num }}</td>
        <td>{{ survey.pet_num }}</td>
        <td>{{ survey.family_num }}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody>
      <colgroup>
        <col width="">
      </colgroup>
      <tr class="cate">
        <td>식사답변 (내가 즐겨먹는 음식)</td>
      </tr>
      <tr>
        <td class="food">{{survey.like_adultfood}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody>
      <colgroup>
        <col width="">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
      </colgroup>
      <tr class="cate">
        <td colspan="6">식성</td>
      </tr>
      <tr class="title">
        <td>뭐든 잘먹는 편이다</td>
        <td>달게 먹는편이다</td>
        <td>짜게먹는편이다</td>
        <td>편식하는 경향이 있다</td>
        <td>식탐이 있는편이다</td>
        <td>야식을 자주 먹는다</td>
      </tr>
      <tr>
        <td>{{survey.taste1}}</td>
        <td>{{survey.taste2}}</td>
        <td>{{survey.taste3}}</td>
        <td>{{survey.taste4}}</td>
        <td>{{survey.taste5}}</td>
        <td>{{survey.taste6}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody>
      <colgroup>
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="">
      </colgroup>
      <tr class="cate">
        <td colspan="5">선호음식</td>
      </tr>
      <tr class="choice">
        <td v-if="survey.likefood1 === '고기'"><em>고기</em> vs 채소 vs 생선</td>
        <td v-else-if="survey.likefood1 === '채소'">고기 vs <em>채소</em> vs 생선</td>
        <td v-else-if="survey.likefood1 === '생선'">고기 vs 채소 vs <em>생선</em></td>
        <td v-if="survey.likefood3 === '국물만'"><em>국물만</em> vs 건더기만 vs 둘다</td>
        <td v-else-if="survey.likefood3 === '건더기만'">국물만 vs <em>건더기만</em> vs 둘다</td>
        <td v-else-if="survey.likefood3 === '둘다'">국물만 vs 건더기만 vs <em>둘다</em></td>
        <td v-if="survey.likefood5 === '기름진 음식'"><em>기름진 음식</em> vs 담백한 음식</td>
        <td v-else-if="survey.likefood5 === '담백한 음식'">기름진 음식 vs <em>담백한 음식</em></td>
        <td v-if="survey.likefood7 === '김치'"><em>김치</em> vs 소시지</td>
        <td v-else-if="survey.likefood7 === '소시지'">김치 vs <em>소시지</em></td>
        <td v-else >김치 vs 소시지</td>
        <td v-if="survey.likefood9 === '파스타'"><em>파스타</em> vs 돈까스</td>
        <td v-else-if="survey.likefood9 === '돈까스'">파스타 vs <em>돈까스</em></td>
      </tr>
      <tr class="choice">
        <td v-if="survey.likefood11 === '케이크'"><em>케이크</em> vs 샌드위치</td>
        <td v-else-if="survey.likefood11 === '샌드위치'">케이크 vs <em>샌드위치</em></td>
        <td v-if="survey.likefood13 === '치즈'"><em>치즈</em> vs 햄</td>
        <td v-else-if="survey.likefood13 === '햄'">치즈 vs <em>햄</em></td>
        <td v-if="survey.likefood15 === '양송이스프'"><em>양송이스프</em> vs 치킨스프</td>
        <td v-else-if="survey.likefood15 === '치킨스프'">양송이스프 vs <em>치킨스프</em></td>
        <td v-if="survey.likefood17 === '냉커피'"><em>냉커피</em> vs 이온음료</td>
        <td v-else-if="survey.likefood17 === '이온음료'">냉커피 vs <em>이온음료</em></td>
        <td v-if="survey.likefood19 === '쌀국수'"><em>쌀국수</em> vs 순두부</td>
        <td v-else-if="survey.likefood19 === '순두부'">쌀국수 vs <em>순두부</em></td>
      </tr>
      <tr class="choice">
        <td v-if="survey.likefood2 === '구운요리'"><em>구운요리</em> vs 국물요리</td>
        <td v-else-if="survey.likefood2 === '국물요리'">구운요리 vs <em>국물요리</em></td>
        <td v-if="survey.likefood4 === '떡볶이'"><em>떡볶이</em> vs 과일 vs 햄버거</td>
        <td v-else-if="survey.likefood4 === '과일'">떡볶이 vs <em>과일</em> vs 햄버거</td>
        <td v-else-if="survey.likefood4 === '햄버거'">떡볶이 vs 과일 vs <em>햄버거</em></td>
        <td v-if="survey.likefood6 === '콜라'"><em>콜라</em> vs 주스</td>
        <td v-else-if="survey.likefood6 === '주스'">콜라 vs <em>주스</em></td>
        <td v-if="survey.likefood8 === '감자튀김'"><em>감자튀김</em> vs 사과</td>
        <td v-else-if="survey.likefood8 === '사과'">감자튀김 vs <em>사과</em></td>
        <td v-if="survey.likefood10 === '치킨'"><em>치킨</em> vs 백숙</td>
        <td v-else-if="survey.likefood10 === '백숙'">치킨 vs <em>백숙</em></td>
      </tr>
      <tr class="choice">
        <td v-if="survey.likefood12 === '김밥'"><em>김밥</em> vs 라면</td>
        <td v-else-if="survey.likefood12 === '라면'">김밥 vs <em>라면</em></td>
        <td v-if="survey.likefood14 === '고구마'"><em>고구마</em> vs 바나나</td>
        <td v-else-if="survey.likefood14 === '바나나'">고구마 vs <em>바나나</em></td>
        <td v-if="survey.likefood16 === '주스'"><em>주스</em> vs 요구르트</td>
        <td v-else-if="survey.likefood16 === '요구르트'">주스 vs <em>요구르트</em></td>
        <td v-if="survey.likefood18 === '팬케이크'"><em>팬케이크</em> vs 시리얼</td>
        <td v-else-if="survey.likefood18 === '시리얼'">팬케이크 vs <em>시리얼</em></td>
        <td v-if="survey.likefood20 === '아메리카노'"><em>아메리카노</em> vs 마끼야또</td>
        <td v-else-if="survey.likefood20 === '마끼야또'">아메리카노 vs <em>마끼야또</em></td>
        <td v-else>아메리카노 vs 마끼야또</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody>
      <colgroup>
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="">
      </colgroup>
      <tr class="cate">
        <td colspan="3">건강 답변  “수면”</td>
        <td colspan="2">건강 답변  “운동”</td>
      </tr>
      <tr class="title">
        <td>자다가 자주 깬다</td>
        <td>쉽게 잠들기 어렵다</td>
        <td>하루 총 수면</td>
        <td>주3회이상 운동을 한다</td>
        <td>운동을 좋아하는 편이다</td>
      </tr>
      <tr>
        <td>{{survey.sleep1}}</td>
        <td>{{survey.sleep2}}</td>
        <td>{{survey.sleep3}}시간</td>
        <td>{{survey.exercise1}}</td>
        <td>{{survey.exercise2}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody>
      <colgroup>
        <col width="25%">
        <col width="25%">
        <col width="25%">
        <col width="">
      </colgroup>
      <tr class="cate">
        <td colspan="4">건강 답변  “음주흡연”</td>
      </tr>
      <tr class="title">
        <td>술을 좋아하는편이다</td>
        <td>독한술 보다는 맥주 · 와인이 좋다</td>
        <td>주 2회이상 마신다</td>
        <td>담배를 1주에 1갑이상 핀다</td>
      </tr>
      <tr>
        <td>{{survey.drinksmoke1}}</td>
        <td>{{survey.drinksmoke2}}</td>
        <td>{{survey.drinksmoke3}}</td>
        <td>{{survey.drinksmoke4}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody>
      <colgroup>
        <col width="25%">
        <col width="25%">
        <col width="25%">
        <col width="">
      </colgroup>
      <tr class="cate">
        <td colspan="4">건강 답변  “감성”</td>
      </tr>
      <tr class="title">
        <td>조용하고 침착하다</td>
        <td>화가 많은 편이다</td>
        <td>잘 웃는다</td>
        <td>스트레스가 많은편이다</td>
      </tr>
      <tr>
        <td>{{survey.sens1}}점</td>
        <td>{{survey.sens2}}점</td>
        <td>{{survey.sens3}}점</td>
        <td>{{survey.sens4}}점</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody>
      <colgroup>
        <col width="33%">
        <col width="33%">
        <col width="">
      </colgroup>
      <tr class="cate">
        <td>최근 2주이내 병원에 간적이 있다면</td>
        <td>가장 빈번하게 병원을 찾는 이유는?</td>
        <td>만성질환이 있나요?</td>
      </tr>
      <tr>
        <td>{{survey.disease_weekly}}</td>
        <td>{{survey.disease_frequently}}</td>
        <td>{{survey.disease_chronic}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody>
      <colgroup>
        <col width="">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
      </colgroup>
      <tr class="cate">
        <td colspan="6">내가 생각하는 내 건강</td>
      </tr>
      <tr class="title">
        <td>근력</td>
        <td>순환기</td>
        <td>간</td>
        <td>신장/비뇨기</td>
        <td>소화기</td>
        <td>피부</td>
      </tr>
      <tr>
        <td>{{survey.health1 || "-" }}</td>
        <td>{{survey.health2 || "-" }}</td>
        <td>{{survey.health3 || "-" }}</td>
        <td>{{survey.health4 || "-" }}</td>
        <td>{{survey.health5 || "-" }}</td>
        <td>{{survey.health6 || "-" }}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody></tbody>
      <colgroup>
        <col width="">
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
      </colgroup>
      <tbody>
      <tr class="cate">
        <td colspan="5">건강식품이나 상시 복용중인 약품</td>
      </tr>
      <tr class="title">
        <td>프로바이오틱스</td>
        <td>홍삼</td>
        <td>한약</td>
        <td>비타민</td>
        <td>기타</td>
      </tr>
      <tr>
        <td>{{survey.healthfood1||"-"}}</td>
        <td>{{survey.healthfood2||"-"}}</td>
        <td>{{survey.healthfood3||"-"}}</td>
        <td>{{survey.healthfood4||"-"}}</td>
        <td>{{survey.healthfood5||"-"}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody></tbody>
      <colgroup>
        <col width="">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
      </colgroup>
      <tbody>
      <tr class="cate">
        <td colspan="6">가족력</td>
      </tr>
      <tr class="title">
        <td>혈관 심장질환<span>(고혈압, 고지혈증, 심근경색 등)</span></td>
        <td>위장관 질환<span>(위염, 궤양, 과민성대장염 등)</span></td>
        <td>호흡기 질환<span>(폐렴, 천식, 만성폐질환 등)</span></td>
        <td>신장질환<span>(신부전, 신증후군 등)</span></td>
        <td>알레르기 질환<span>(신부전, 신증후군 등)</span></td>
        <td>내분비<span>(비만, 당뇨, 갑상선 등)</span></td>
      </tr>
      <tr>
        <td>{{survey.hypertension || "-"}}</td>
        <td>{{survey.heart_disease || "-"}}</td>
        <td>{{survey.stroke || "-"}}</td>
        <td>{{survey.breast_cancer|| "-"}}</td>
        <td>{{survey.lung_cancer|| "-"}}</td>
        <td>{{survey.diabetes || "-"}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2 surveytb_etc">
      <tbody></tbody>
      <colgroup>
        <col width="">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
      </colgroup>
      <tbody>
      <tr class="title">
        <td>뇌질환<span>(치매, 뇌졸중, 간질 등)</span></td>
        <td>각종 암<span>(폐암, 위암, 간암 등)</span></td>
        <td>류마치스, 면역질환</td>
        <td>감염질환</td>
        <td>기타</td>
        <td></td>
      </tr>
      <tr>
        <td>{{survey.hyperlipidemia|| "-"}}</td>
        <td>{{survey.osteoporosis|| "-"}}</td>
        <td>{{survey.colorectal_cancer|| "-"}}</td>
        <td>{{survey.thyroid_cancer|| "-"}}</td>
        <td>{{survey.stomach_cancer|| "-"}}</td>
        <td>-</td>
      </tr>
      </tbody>
    </table>
  </section>
  <!-- 페이지끝 -->


  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_1.png" alt="balance1 성장"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="survey_box">
      <ul class="three">
        <li>
          <span><em>· Sample ID</em>{{sample.code}}</span>
          <span><em>· 작성일</em>{{moment(new Date()).format("YYYY년 M월 D일")}}</span>
          <span><em>· 이름</em>{{survey.name}}</span>
        </li>
        <li>
          <span><em>· 나이</em>{{survey.age}}</span>
          <span><em>· 생년월일</em>{{survey.birthday_year+"년 "+survey.birthday_month+"월 "+survey.birthday_day + "일"}}</span>
          <span><em>· 신장</em>{{survey.height}}cm</span>
        </li>
        <li>
          <span><em>· 체중</em>{{survey.weight}}kg</span>
          <span><em>· BMI</em>{{survey.bmi}}</span>
          <span><em>· 가족정보</em>총 {{survey.parent_num + survey.brother_num}}명 (부모{{survey.parent_num}},형제{{survey.brother_num}})</span>
        </li>
      </ul>
    </div>
    <div class="data_box">
      <ul>
        <li style="height: 476px;">
          <h3>키성장</h3>
          <span class="graph_area_grow"> <v-chart style="left:-38px;" :options="heightChartData" :init-options="{ width: 500, height : 423}" theme='shine' /></span>
          <span class="data_txt mint">36개월 여아의 범위는 80~100cm 로 현재 93cm로 연령대의 하위에서 점차 평균치로 상승중입니다.</span>
        </li>
        <li>
          <h3>체중의 변화</h3>
          <span class="graph_area_grow"> <v-chart style="left:-25px;" :options="weightChartData" :init-options="{ width: 500, height : 423}" theme='shine' /></span>
          <span class="data_txt mint">36개월 여아의 범위는 80~100cm 로 현재 93cm로 연령대의 하위에서 점차 평균치로 상승중입니다.</span>
        </li>
      </ul>
      <br><br><br>
      <div class="txt_area">
        장 미생물 중에서 성장에 직접적인 영향을 미치는 균이 존재하지 않습니다.<br><br>

        하지만 유해균의 영향으로 영양분의 적절한 분해와 흡수가 방해되어 성장을 저해한다면 유해균을 줄이는 노력이 필요하겠습니다.<br><br>

        성장에는 유전적인 요인이 많지만 후천적인 노력도 많은 영향을 준다고 합니다. 그저 많이 먹는다고 잘 크는게 아닙니다.<br>
        소아 비만은 성조숙증을 유발하기도 하여 오히려 성장을 방해할 수도 있습니다.
      </div>
    </div>

    <div class="tip_box">
      <div>
				<span>
				성장기 어린이는 성장에 필요한 다양한 영양소가 고루 섭취되어야 합니다.<br><br>
				장 미생물의 분포가 이상이 생길 경우 이는 적절한 영양소의 균형이 깨어진 경우이거나 혹은 다른 이유로 장내 환경이 변화된 원인으로 볼 수 있으므로 장내 미생물의 적절한 균형이 깨어진 경우 적절한 성장에 영향을 줄 수 있습니다.<br>
				또한 어린이의 비만은 또 다른 중요한 위험신호입니다.<br>
				그저 많이 먹는다고 빨리 크는 건 아닙니다. 균형 있는 식사와 운동이 중요합니다.<br><br>
				어린이의 비만은 소아 당뇨 및 성장을 저해하는 요인이 될 수 있다는 점을 명심해야 합니다. 잘 먹는다고 균형을 잊으면 <br>곤란합니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>5</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->


  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_2.png" alt="식생활"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자 마지막입니다. </div>
    <div class="survey_box">
      <ul class="three">
        <li>
          <span><em>· Sample ID</em>{{sample.code}}</span>
          <span><em>· 작성일</em>{{moment(new Date()).format("YYYY년 M월 D일")}}</span>
          <span><em>· 이름</em>{{survey.name}}</span>
        </li>
        <li>
          <span><em>· 나이</em>{{survey.age}}</span>
          <span><em>· 생년월일</em>{{survey.birthday_year+"년 "+survey.birthday_month+"월 "+survey.birthday_day + "일"}}</span>
          <span><em>· 신장</em>{{survey.height}}cm</span>
        </li>
        <li>
          <span><em>· 체중</em>{{survey.weight}}kg</span>
          <span><em>· BMI</em>{{survey.bmi}}</span>
          <span><em>· 가족정보</em>총 {{survey.parent_num + survey.brother_num}}명 (부모{{survey.parent_num}},형제{{survey.brother_num}})</span>
        </li>
      </ul>
    </div>
    <div class="babylikefoodtbwrap">
      <h3>내가 고른 내 식탁</h3>
      <food-table></food-table>
    </div>
    <div class="tip_box">
      <div>
				<span>
				즐겨먹는 음식이 어떻게 편중되어 있는지 확인해 보시기 바랍니다.<br>
				국물있는 요리를 좋아하는 사람이나 마른반찬을 주로 선택하는 경우 혹은 다양한 간편식을 선호하는 경우도 있습니다. 식단의 편중은 결국 장의 부담이 됩니다. 또한 아이는 부모의 식이 취향을 물려받는 경우가 대부분이란 점을 명심하시기 바랍니다.<br>
				음식은 단순히 영양분의 구성 뿐 아니라 장내 미생물의 입장에서도 적절한 균형이 필요합니다.<br>
				단백질, 탄수화물, 지방, 무기질, 비타민, 당분 등이 인간에게 필요한 성분이라면 식이 섬유와 흡수가 어려운 다당류 등은 <br> 미생물들의 중요한 먹이가 되며 또한 대변의 재료가 됩니다.
				식이 섬유가 부족한 영양분만 가득한 식단은 변비를 유발할 수도 있습니다.

				</span>
      </div>
    </div>
<!--    <div class="cmt_box">
      모든 부모들의 공통적인 바램이 잘 먹고 잘 크는 것입니다.
      키는 모든 부모들이  가장 중요하게 생각하는 요소이며, 키 크게 만드는데 많은 노력을 아끼지 않습니다.
      키는 유전인까? 아닐까 하는 여러 논쟁이 있지만 논쟁에서 떨어져 상식적인 수준에서 판단할때 유전적인 요인도
      있고 후천적인 요인도 존재하며 누군가는 유전적인 요인이 더 크게 작용하고 누군가는 영양적인 요인이 더 많이
      작용하기도 한다는 게 여러 학설을 요약한 내용입니다.
    </div>-->
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>6</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_2.png" alt="식생활"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자 마지막입니다. </div>
    <div class="data_box data_box_food">
      <ul class="food_data_graph">
        <li>
					<span class="title">
						식이취향 (식재료)
						<span><em>고객님의 취향</em><em>적정수준</em></span>
					</span>
          <div class="graph_area_living">
            <v-chart :options="tastesList.type1"  :initOptions="{
            width: 500, height : 300 }" theme='shine' />
          </div>
        </li>
        <li>
					<span class="title">
						식이취향 (조리법)
						<span><em>고객님의 취향</em><em>적정수준</em></span>
					</span>
          <div class="graph_area_living">
            <v-chart :options="tastesList.type2"  :initOptions="{
            width: 500, height : 300 }" theme='shine' />
          </div>
        </li>
      </ul>
      <div class="txt_area">
        같은 식탁에 앉아도 선택은 결국 개인의 취향입니다.<br>
        고기만 골라 먹는 아들, 다이어트 한다면서 면만 좋아하는 딸, 뭐든 다 잘 먹지만 너무 많이 먹는 아빠,
        결국 같은 식탁에서 식구들은 조금씩 다르게 식사를 하게 됩니다.<br><br>

        설문으로 알아본 당신의 취향을 식재료별로 혹은 조리방법으로 구분해 알아봅시다.<br><br>

        육류, 탄수화물, 채소, 발효식품 등의 취향과 달고 짜게 혹은 기름지게 먹는 습관까지 알아봅니다.

        어떤 영역도 절대적으로 나쁘고 좋은 건 없습니다. 얼마나 고루 먹는 습관을 가지고 있는지 알아보는 테스트 결과입니다.<br><br>

        실제 먹는 음식과 다를 수도 있지만 내 취향을 알고 적절하게 조절할 수 있다면 더욱 건강한 장을 가질 수 있습니다.
      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				인류는 아주 오랫동안 다른 지역에서 다른 문화와 자연에 적응하며 살아왔습니다. 수만 년의 시간 동안 우리 몸은 다른 식습관에 적응이 되어 왔습니다. 국가별로 Microbiome에 대한 연구를 다르게 하는 이유입니다.<br><br>

				오랜 시간 동안 길들여진 우리의 몸이 최근 수십 년간 급격히 서구화된 식단에 잘 적응하지 못하는 경우가 많습니다. 미국 이민자 사회에서 아이의 당뇨병 유병율이 백인에 비해 크게 차이가 나는 연구결과가 있습니다. 같은 음식을 먹어도 인종마다 다른 결과를 보이고 있습니다.<br><br>

				또 정 반대로 부모의 채식주의 때문에 아이에게 채식만을 강요하는 것도 바람직하지 않습니다. 성장기의 어린이는 다양한 동식물성 단백질이 필요합니다. 음식을 균형 있게 섭취하는 것이 무엇보다 중요합니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>7</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_3.png" alt="운동"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자 마지막입니다. </div>
    <div class="survey_box">
      <ul class="two">
        <li class="wide">
          <span><em>· 주3회이상 땀흘리는 운동을 한다</em>{{survey.exercise1}}</span>
          <span><em>· 운동을 좋아하는 편이다</em>{{survey.exercise2}}</span>
        </li>
      </ul>
    </div>
    <div class="data_box">
      <div class="txt_area margintop0">
        체중을 조절하기 위해서는 유산소 운동이 좋습니다.<br><br>
        그리고 면역력을 증가시키기 위해서는 근육 운동이 필수적입니다.<br>
        근육운동이라 함은 근육이 부하는 느끼는 운동으로 근육이 뻐근함을 느끼게 하는 운동을 의미합니다.<br><br>

        평지를 걷는 산책보다는 등산이 더 좋고 스트레칭보다는 필라테스가 좋겠습니다. 이외 땀을 흘리는 모든 운동은 도움이 됩니다.<br><br>

        유산균은 장에 뿌리는 씨앗과 같습니다. 좋은 씨앗을 뿌리는 것이 중요하지만 씨앗이 자라기 위한 퇴비가 필요합니다.<br>
        장에서 퇴비가 되는 것이 프리바이오틱스 혹은 식이 섬유입니다.<br><br>

        프리바이오틱스는 유익균의 먹이로서 중요하지만 더 좋은 프리바이오틱스는 자연의 식물에서 섭취하는 방법입니다.<br>
        마지막으로 씨를 뿌리고 먹이를 주었으니 햇빛과 물을 주어야 하는데, 장의 생태계에서 태양과 물을 주는 역할을 하는 것이 운동입니다.<br><br>

        운동은 단순히 근육을 키워줄 뿐 아니라 유익균이 더 잘 살 수 있도록 도와주는 중요한 역할을 합니다.<br>
        씨만 열심히 뿌린다고 곡식이 자라지 않습니다. 퇴비를 주고 햇빛과 물을 주어야 합니다.<br><br>
        유산균만 열심히 먹는다고 장이 좋아지기는 어렵습니다.
      </div>
      <div class="img_exercise_youth"></div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				동물이 식물과 다른 이유는 움직이기 때문입니다.
				움직이지 않는 인간에게 잉여의 영양분은 때론 독이 되기도 합니다.<br>
				운동이 옵션이 아닌 필수입니다. 특히 성장기의 아이는 더욱더 그러합니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>8</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_youth4.png" alt="수면"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자 마지막입니다. </div>
    <div class="survey_box">
      <ul class="two">
        <li>
          <span><em>· 자다가 자주 깬다</em>{{survey.sleep2}}</span>
          <span><em>· 쉽게 잠들기 어렵다</em>{{ (survey.age <= 18)?survey.sleep3:'성인은 sleep 항목이 한개 없음'}}</span>
          <span><em>· 하루 총 수면</em>{{(survey.age <= 18)?survey.sleep4:survey.sleep3}} 시간</span>
<!--          <span><em>· 밤낮이 바뀌어 잔다 </em>{{survey.sleep1}}</span>
          -->
        </li>
        <li>
					<span><em>· 조용하고 침착하다</em>{{survey.sens1}}점</span>
          <span><em>· 화가 많은 편이다</em>{{survey.sens2}}점</span>
          <span><em>· 잘 웃는다</em>{{survey.sens3}}점</span>
          <span><em>· 스트레스가 많은편이다</em>{{survey.sens4}}점</span>
        </li>
      </ul>
    </div>
    <div class="data_box data_margint0">
      <div class="txt_area">
        <h3>수면, 감성</h3>
        생활 중 자연스럽게 몸에 베어있는 습관은 장 미생물 뿐 아니라 모든 건강에 영향을 미치는 요소입니다.<br><br>
        수면시간은 피로를 회복하는 시간일 뿐 아니라 아이들에게 성장에 매우 중요한 시간입니다.<br>
        또 스트레스 역시 성장과 면역에 중요한 역할을 합니다.<br><br>
        장 미생물 중에서는 다양한 호르몬 분비에 관여하고 있는 균들이 있습니다.<br><br>
        이 장 미생물들이 분비하는 각종 호르몬은 신경전달 물질로서 안정, 흥분, 우울감 등의 감성적 요소와 관련이 있을 것으로 추정하고 있습니다.
      </div>
    </div>
  </section>
  <div class="footer">
    <div class="img_sleeping_youth"></div>
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>9</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="cover">
    <div><img src="img/report_information3.jpg"></div>
  </section>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_5_adult.png" alt="가족력"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자 마지막입니다. </div>

    <table class="surveytb surveytb_2">
      <tbody>
      <colgroup>
        <col width="33%">
        <col width="33%">
        <col width="">
      </colgroup>
      <tr class="cate">
        <td>최근 2주이내 병원에 간적이 있다면</td>
        <td>가장 빈번하게 병원을 찾는 이유는?</td>
        <td>만성질환이 있나요?</td>
      </tr>
      <tr>
        <td>{{survey.disease_weekly}}</td>
        <td>{{survey.disease_frequently}}</td>
        <td>{{survey.disease_chronic}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody>
      <colgroup>
        <col width="">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
      </colgroup>
      <tr class="cate">
        <td colspan="6">내가 생각하는 내 건강</td>
      </tr>
      <tr class="title">
        <td>근력</td>
        <td>순환기</td>
        <td>간</td>
        <td>신장/비뇨기</td>
        <td>소화기</td>
        <td>피부</td>
      </tr>
      <tr>
        <td>{{survey.health1 || "-" }}</td>
        <td>{{survey.health2 || "-" }}</td>
        <td>{{survey.health3 || "-" }}</td>
        <td>{{survey.health4 || "-" }}</td>
        <td>{{survey.health5 || "-" }}</td>
        <td>{{survey.health6 || "-" }}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody></tbody>
      <colgroup>
        <col width="">
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
      </colgroup>
      <tbody>
      <tr class="cate">
        <td colspan="5">건강식품이나 상시 복용중인 약품</td>
      </tr>
      <tr class="title">
        <td>프로바이오틱스</td>
        <td>홍삼</td>
        <td>한약</td>
        <td>비타민</td>
        <td>기타</td>
      </tr>
      <tr>
        <td>{{survey.healthfood1||"-"}}</td>
        <td>{{survey.healthfood2||"-"}}</td>
        <td>{{survey.healthfood3||"-"}}</td>
        <td>{{survey.healthfood4||"-"}}</td>
        <td>{{survey.healthfood5||"-"}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody></tbody>
      <colgroup>
        <col width="">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
      </colgroup>
      <tbody>
      <tr class="cate">
        <td colspan="6">가족력</td>
      </tr>
      <tr class="title">
        <td>혈관 심장질환<span>(고혈압, 고지혈증, 심근경색 등)</span></td>
        <td>위장관 질환<span>(위염, 궤양, 과민성대장염 등)</span></td>
        <td>호흡기 질환<span>(폐렴, 천식, 만성폐질환 등)</span></td>
        <td>신장질환<span>(신부전, 신증후군 등)</span></td>
        <td>알레르기 질환<span>(신부전, 신증후군 등)</span></td>
        <td>내분비<span>(비만, 당뇨, 갑상선 등)</span></td>
      </tr>
      <tr>
        <td>{{survey.hypertension || "-"}}</td>
        <td>{{survey.heart_disease || "-"}}</td>
        <td>{{survey.stroke || "-"}}</td>
        <td>{{survey.breast_cancer|| "-"}}</td>
        <td>{{survey.lung_cancer|| "-"}}</td>
        <td>{{survey.diabetes || "-"}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody></tbody>
      <colgroup>
        <col width="">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
      </colgroup>
      <tbody>
      <tr class="title">
        <td>뇌질환<span>(치매, 뇌졸중, 간질 등)</span></td>
        <td>각종 암<span>(폐암, 위암, 간암 등)</span></td>
        <td>류마치스, 면역질환</td>
        <td>감염질환</td>
        <td>기타</td>
        <td></td>
      </tr>
      <tr>
        <td>{{survey.hyperlipidemia|| "-"}}</td>
        <td>{{survey.osteoporosis|| "-"}}</td>
        <td>{{survey.colorectal_cancer|| "-"}}</td>
        <td>{{survey.thyroid_cancer|| "-"}}</td>
        <td>{{survey.stomach_cancer|| "-"}}</td>
        <td>-</td>
      </tr>
      </tbody>
    </table>
    <div class="data_box data_margint0">
      <div class="txt_area">
        <h3>자가건강, 가족력</h3>
        시대가 변하면서 인간의 건강을 위협하는 요소는 변화하고 있습니다.<br>
        항생제가 발명되기 전 인간은 갖가지 전염병에 속수무책으로 당하고 수명이 제한되었습니다. 항생제가 발명된 이후 인류의 수명은 <br>급격하게 증가되었지만 또 다른 부작용으로 장내 미생물의 불균형으로 인한 또 다른 질병이 위험이 되고 있습니다. 병균과 싸우기 위한 항생제는 <br>너무나 중요한 인류의 선물이지만 장내 미생물의 입장에서는 큰 시련이 되기도 합니다.<br><br>

        항생제의 과도한 복용이 문제가 되기도 하지만 병을 치료하기 위한 약을 장에 좋지 않다고 먹지 않는 것도 문제입니다.<br>
        적절하게 치료를 받고 유해균이 장을 점령하기 전에  좋은 균을 다시 자리 잡도록 관리하는 것이 현명한 방법입니다.<br>

        항생제 외에도 우리가 먹은 다양한 약들은 장내 미생물에 여러 가지 방향으로 영향을 주고 있습니다.<br>
        너무나 다양한 요소들 때문에 개별적인 영향을 다 파악할 수 없지만 전후의 변화를 관찰함으로써 다양한 요소들이 미치는 영향을 파악할 수 <br>있습니다.

      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				가족력에서 제일 중요한 부분이 생활 습관입니다.<br><br>

				특히 같이 식생활을 하는 ‘식구’는 유사한 식이 취향을 가지게 되며 장기간 지속되면 장의 환경 즉 장 미생물의 생태계가 <br>유사해지기도 합니다. 장 미생물의 생태계는 대장암이나 당뇨와 매우 관계가 높다고 알려져 있으며 이것이 식습관과 매우 <br>관련이 높은 것으로 판단하고 있습니다.<br>
				좋은 식습관을 가지도록 하는 밥상머리 교육이 그만큼 중요하다는 의미입니다. 하지만 부모의 그릇된 지식이나 선입견 때문에 잘못된 식습관을 갖게 되는 경우도 있습니다.<br><br>
				장 미생물 검사는 그런 측면에서도 매우 중요합니다.<br>그동안 내 생활습관이 몸에 올바로 영향을 미쳤는지 확인할 수 있습니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>13</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_summary.png" alt="balance summary"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="balance_summary_main">
      <div>
        <v-chart  :options="balance.totalBalanceGaugeData" ref="total" theme='shine' :initOptions="{ width: 700, height : 600}" ></v-chart>
      </div>
      <strong>장내 미생물 기능별 분포 수준</strong>
      <!-- class grade1~5까지 제어  -->
      <span :class="balance.totalBalanceGaugeData.levelGroup.cssClass">
      </span>
    </div>
    <div class="tip_box">
      <div>
				<span>
				균형 지수의 Level은 Level-2 에서 Level2 까지 5단계로 구분됩니다.<br>
				( Level -2 : 경고, Level -1 : 주의, Level 0 : 보통, Level 1 : 양호, Level 2 : 우수 )<br><br>

				해당 기능에 관련이 있는 수십 종의 균종이 균형을 이루고 있는지 알아보는 그래프입니다. 해당 기능과 관련이 있는 유익균과 유해균중 유해균의 비율이 높은 경우 Level이 낮아집니다. 반대로 유익균의 비율이 높으면 Level이 높아집니다.<br><br>

				이 Level은 질병을 의미하진 않습니다. 사람에 따라 면역력이 약해질 때, Level이 낮은 경우에는 유해성을 가진 균종들이 유익균보다 더 많아지고 있으니 주의해야 한다는 의미입니다.<br><br>

				장 미생물은 다양한 질병과 관련이 있다고 알려져 있지만 질병과 모두 직접적으로 연관되지 않는 경우도 많습니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>14</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_summary.png" alt="balance summary"><span class="miso"></span></h2>
  </div>
  <section class="margin0">
    <ul class="balance_summary_detail">
      <li>
        <ul>
          <li  class="img_gauge">
            <v-chart  :options="balance.gutBalanceGaugeData" ref="total" theme='shine' :initOptions="balanceGauge.size" ></v-chart>
          </li>
          <li>
            <span><strong>장건강</strong><em :class="balance.gutBalanceGaugeData.levelGroup.cssClass"><em>level</em><span>2</span></em></span>
            <span>장건강과 관련된 53종의 장내 미생물 분석 결과입니다. </span>
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li  class="img_gauge">
            <v-chart  :options="balance.immunityBalanceGaugeData" ref="total" theme='shine' :initOptions="balanceGauge.size" ></v-chart>
          </li>
          <li>
            <span><strong>장건강</strong><em :class="balance.immunityBalanceGaugeData.levelGroup.cssClass"><em>level</em><span>1</span></em></span>
            <span>면역과 관련된 53종의 장내 미생물 분석 결과입니다. </span>
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li  class="img_gauge">
          <v-chart  :options="balance.ageBalanceGaugeData" ref="total" theme='shine' :initOptions="balanceGauge.size" ></v-chart>
          </li>
          <li>
            <span><strong>장건강</strong><em :class="balance.ageBalanceGaugeData.levelGroup.cssClass"><em>level</em><span>0</span></em></span>
            <span>장/면역 밸러스 지수는 유익균과 유해균의 균형을 나타내고 있습니다.<br>어떤 유해균이 특별히 많은지 살펴볼 수 있습니다.</span>
          </li>
        </ul>
      </li>
    </ul>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>15</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_aibchart.png" alt="aibchart"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="data_box data_box_graph">
      <h3>임상적 분류</h3>
      <div class="graph_area_diversity">
        <v-chart :options="aibChart1" ref="polar" theme='shine' :initOptions="{width: 1038}"/>
      </div>
      <h3>생물학적 분류</h3>
      <div class="graph_area_diversity">
        <v-chart :options="aibChart2" theme='shine' :initOptions="{width: 1038}"/>
      </div>
      <div class="txt_area">
        AiB Chart는 AiBiotics 사의 독창적인 Microbiome 지도입니다.<br>
        수 백여 종의 장내 미생물을 보편적으로 많이 존재하는 순서로 나열한 다음 나의 수준을 비교하는 그래프입니다. 보편적으로 존재하는 장 미생물이 결핍되거나, 혹은 특정한 균이 특이하게 많이 존재하는 경우를 살펴보는 방법입니다.
      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				임상적 분류 : 많은 연구를 통해 유해성이 보고되거나, 유익성이 보고 된 경우 유해균과 유익균으로 구분하였습니다. 하지만 유해균으로 분류된 붉은색 선이 반드시 병을 유발한다는 증거는 없습니다. 하지만 그럴 확률은 높은게 사실입니다. 또한 결핍이 많은 경우에도 역시 임상적으로 여러가지 증상을 보이는 경우가 많습니다.<br><br>

				생물학적 분류 : 장 미생물의 여러가지 분류 기준 중에서 Phylum(문) 기준으로 분류한 그래프입니다. 생물학적인 구분기준으로 나누었지만 각 균들의 특성들이 있어 장 건강을 일부 대면하기도 합니다. 박테로이데테스(Bacteroidetes)와 퍼미큐티스(Firmicutes)가 가장 보편적으로 많은 균입니다. 이외에 프로테오박테리아나 푸조박테리아에는 병원성 균이 많이 포함되어 있습니다.

				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>16</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_index.png" alt="AiBINDEX"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="data_box index">
      <ul class="food_data_graph aib_index">
        <li>
          <div id="now"></div>
          <div class="index_old">
            <span class="title">한국인 나이별 다양성 분포 중 나의 위치</span>
            <div>
            <v-chart :options="byAgeDiversity" :init-options="{width:500, height:300}"  />
            </div>
          </div>
        </li>
        <li>
          <span class="title">AiB index</span>
          <div class="graph_area_living_freq">
            <v-chart :options="freqDistributionListChart" :init-options="{width:520, height:344}" theme='shine' />
          </div>
        </li>
      </ul>
      <ul class="index_result">
        <li>
          <span class="standard">총 검사 대상 균종 <em>{{aibTotal.avg||0}}</em>개 중</span>
          <span>확인된 균종 <em>{{aibTotal.user||0}}</em>종</span>
        </li>
      </ul>
      <div class="txt_area">
        다양성 분포는 AiB index를 이용한 히스토그램으로 표현하고 있습니다.
        전체 분포 중에서 당신의 다양성의 위치를 확인하세요.<br><br>

        장 미생물의 분석에서 다양성을 매우 중요한 Index로 여기고 있지만 그 정확한 기준이 없습니다.
        그저 많기만 하다고 좋은 것만은 아닙니다.<br>
        AiB index는 '과잉'을 제외한 분포를 Log 함수를 이용하여 다양성 지수를 산출하고 있습니다.
      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				인체에 존재하는 미생물은 대략 40~50조 정도로 알려져 있습니다. 분석을 통해 우리가 알 수 있는 균종은 불과 수백 종에 불과합니다.
				20년 동안 연구 되어온 균중들의 분포를 알아보면 얼마나 다양한 균종들이 내 몸에서 사는지 확인할 수 있습니다.<br>
				성장하면서 다양성은 지속적으로 증가하는 것이 일반적이지만, 질병이나 투약 혹은 다른 원인으로 인하여 미생물의 생태계는 지속 변화합니다.<br><br>

				AiB Index는 다양성을 표현하기 위한 방법으로, 발견되는 종의 수와 양을 결합하여 Index(지수)로 표현하고 있습니다.<br>
				AIB Index는 다양성 Index입니다. 한종의 미생물만 많을 때 보다는 다양한 종이 어울려 존재할 때 Index가 높아집니다. AiB의 Database에서 연령별로 증가되는 Trend를 확인할 수 있습니다.
				AiB Index는 존재하는 미생물의 수량을 다소 복잡한 수학공식을 이용하여 다양성 지수로 만들었습니다. 크기의 숫자가 중요하지는 않습니다. 다만 연령대 별로 증가하는 Index trend 중에 나의 상대적인 위치를 확인하고 개선할 점을 찾는게 중요합니다.

				</span>
      </div>
    </div>
    <div class="index_bobg"></div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>17</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_phylum.png" alt="phylum분석"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="txt_top">
      Microbiome 의 생태학적인 분류로 20여개의 Phylum(문) Level의 분류법이 있습니다.<br>
      Bacteroidetes와 Firmicutes가 가장 보편적인 균들이며 유익균, 유해균, 공생균이 섞여있습니다.<br>
      반면에 Fusobacteria와 ProteoBacteria는 유해성이 큰 균들이 다수 포함되어 있어 일반적으로 유해균으로 분류합니다.<br>
      Major 균종의 비율과 소수 균종이 특이하게 증가될 떄 자세하게 확인해 보아야 합니다.
    </div>
    <div class="analysis_box">
      <ul>
        <li>
          <v-chart :options="phylum.avgPieData" :init-options="{width: 519, height: 473}" theme='shine' />
        </li>
        <li>
          <v-chart :options="phylum.userPieData" :init-options="{width: 519, height: 473}" theme='shine' />
        </li>
      </ul>
    </div>
    <dl class="analysis_list">
      <dt>
        <strong>Actinobacteria</strong>
        <span>
          <v-chart :options="reportPhylum.Actinobacteria"  :init-options="{width: 290, height: 180}" />
        </span>
      </dt>
      <dd>
        악티노박테리아(Actinobacteria)의 대표 선수는 비피더스라고 불리는 비피도박테리아(Bifidobacteria) <br>입니다.<br>
        비피더스는 대표적인 유산균으로서 장 건강에 도움이 되는 균으로 규명되어  먹으면 건강에 도움을 주는 균인 <br>‘프로바이오틱스’의 Label을 득한 균입니다. 비피더스외에도 많은 종류의 균들이 있으며 공생균, 유해균도 다수 포함되어 있습니다.
      </dd>
    </dl>
    <dl class="analysis_list">
      <dt>
        <strong>Firmicutes</strong>
        <span>
          <v-chart :options="reportPhylum.Firmicutes"  :init-options="{width: 290, height: 180}" />
        </span>
      </dt>
      <dd>
        퍼미큐티스( Firmicutes)는 마이크로바이옴 분석시 가장 많은 종류가 발견되는 균종입니다.<br>
        지방대사와의 관련성 때문에 비만균이라는 오명을 쓰고 있지만 실제로는 락토바실러스를 비롯한 다양한 <br>유산균이 포함되는 그룹입니다. 악티노박테리아와 같이 유익균과 유해균이 모두 포함되어 있습니다. 하지만 microbiome 검사에서 가장 중요한 ‘다양성’의 측면에서 기여도가 가장 큰 그룹입니다.
      </dd>
    </dl>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>18</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_phylum.png" alt="phylum분석"><span class="miso"></span></h2>
  </div>
  <section>
    <dl class="analysis_list nextPage">
      <dt>
        <strong>Bacteroidetes</strong>
        <span>
          <v-chart :options="reportPhylum.Bacteroidetes"  :init-options="{width: 290, height: 180}" />
        </span>
      </dt>
      <dd>
        박테로이데테스( Bacteroidetes)는 가장 보편적인 장내 미생물입니다.<br>

        주요 구성원인 박테로이데스(Bacteroides) 와 프리보텔라(Prevotella)는 전체 장내 미생물의 구성 중 50% <br>이상을 차지하고, 정상적인 식사를 하는 경우에 이 비율을 벗어나지 않습니다.
        Entero type 분석에서 이 두 균종의 비율로 식이 패턴을 판단하기도 합니다. 먹는 음식에 따라 위 두 종의 박테리아의 비율이 변화합니다.

      </dd>
    </dl>
    <dl class="analysis_list">
      <dt>
        <strong>Proteobacteria</strong>
        <span>
          <v-chart :options="reportPhylum.Proteobacteria"  :init-options="{width: 290, height: 180}" />
        </span>
      </dt>
      <dd>
        프로테오박테리아(Proteobacteria)는 알파, 베타, 감마 3그룹으로 구분하고 그 중에서도 감마그룹은 병원성 유해균이 많이 포함되어 있습니다.<br> 장속의 병원성 유해균은 그 자체로 질병을 유발하는 정말 나쁜균도 있지만 대부분은 ‘기회감염균’ 으로 분류되는데 평소 장에서는 괜찮지만 면역력이 떨어지는 경우나 장의 점막이 약해지는 경우에 감염을 유발하는 균들입니다.<br><br>
        따라서 프로테오박테리아는 ‘존재’보다는 ‘증가’에 주의해야 합니다.
      </dd>
    </dl>
    <dl class="analysis_list">
      <dt>
        <strong>Fusobacterium</strong>
        <span>
          <v-chart :options="reportPhylum.Fusobacteria"  :init-options="{width: 290, height: 180}" />
        </span>
      </dt>
      <dd>
        푸조박테리아는(FusoBacteria)는 대부분이 기회감염균으로서 병원성균으로 인식하고 있습니다.<br>
        물고기의 내장에서는 공생균으로 존재하지만 인간의 장에서는 유해성이 강한 편입니다.<br>
        일부 연구에서는 대장 용종의 원인으로 추정 연구결과가 있기도 합니다.<br>
        정상인의 80% 이상은 NGS 검사에서 이 균이 유의하게 발견되지 않습니다. 하지만 없는 것이 아니라 아주 작은량이 존재하기 때문에 다른 정밀검사에서는 발견되기도 합니다.<br><br>
        따라서 이 균이 일정량 이상 확인 발견되는 경우에는 장건강에 대하여 주의를 해야 할 시기가 되었음을 알려줍니다.
      </dd>
    </dl>
    <div class="tip_box">
      <div>
				<span>
				장내 미생물이 주목을 끈 계기는 2006년 학술지 '네이처(nature)'에 미국 워싱턴의 고든(Jeffory Gordon) 연구팀이 "비만이 장내세균과 관련이 있다"는 연구 논문을 발표한 것이었다. 이 논문은 정상 쥐와 비만 쥐의 장내세균 분포를 비교해, 비만 쥐에서 박테로이데스(Bacteroides)균은 적고 퍼미큐티스(Fermicutes)균이 증가한 것을 규명했다. 그 후 10년 뒤인 2016년에는 예일대학교의 쉴만(Gerald Shulman) 연구진이 같은 네이처(nature)지에 논문을 실어 구체적으로 장내세균이 비만에 이르는 기전을 규명했다. 장내 미생물은 인체가 미처 소화하지 못한 식이 섬유를 발효시켜서 짧은 지방산(short chain fatty acid)으로 분해하고, 이 과정에서 많은 가스가 발생해 장을 불편하게 만든다. 이 짧은 지방산은 뇌-혈관 관문(brain-blood barrier,BBB)을 통과해 시상하부의 식욕중추를 자극한다. 이로 인해 위에서는 식욕을 불러일으키는 그렐린 호르몬을 자극해 더 많은 식사를 하게하고, 췌장에선 인슐린을 자극해 지방세포를 살 찌우게 하는 것이다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>19</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_entero.png" alt="장유형"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="txt_top">
      장 유형은 장내 세균의 분포를 기준으로 판단하는 패턴입니다.<br>
      식이패턴, 운동등의 생활습관, 약물의 복용등의 다양한 요인에 의하여 만들어 지는 장의 유형을 나타냅니다
    </div>
    <div class="analysis_box">
      <ul>
        <li>
          <v-chart :options="enterotypePieChart.avg" :init-options="{width: 519, height: 473}" theme='shine' />
        </li>
        <li>
          <v-chart :options="enterotypePieChart.user" :init-options="{width: 519, height: 473}" theme='shine' />
        </li>
      </ul>
    </div>
    <div class="data_box">
      <div class="txt_area margintop0">
        장 유형은 2011년 연구된 논문에서 '장기적인 식습관으로 인하여 만들어지는 몇 가지 패턴'을 확인하고 이 Type을 장 유형으로 표현하고 있습니다.<br><br>


        <span>EnteroType1: 박테리오데스(Bacteriodes)</span><br>
        육식을 주로 하는 유럽인의 일반적인 Type입니다. 육식과 식이섬유가 부족한 전분을 섭취하는 경우 많이 나타납니다. 빵과 고기 혹은 밥과 고기를 주로 먹는 경우에 해당합니다.
        한국인의 평균 수준은 60~70% 정도입니다.
        <br><br>

        <span>EnteroType2: 프리보텔라(Prevotella)</span><br>
        채식 특히 식이섬유소가 풍부한 식사를 하는경우에 프리보텔라가 번성합니다. 한국인 성인에 적합한 Type으로 판단하고 있습니다.
        분유를 먹은 아기는 분유에 포함된 다양한 올리고당때문에 프리보텔라가 많습니다.<br>하지만 성인식을 시작하면 급격하게 박테로이데스 Type으로 전환됩니다. <br><br>

        <span>EnteroType3: 루미노코커스(Ruminococcus)</span><br>
        포도당 흡수를 도와서 비만을 유발하는 균으로 알려져 있지만 유해성보다는 공생균으로 인식하고 있습니다.

      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				EnteroType은 주로 장기적인 식이 취향에 의하여 장 미생물의 우점종이 변경되는 경우입니다.<br><br>

				프리보텔라는 채식과 관련되어 있으며 다양한 방법으로 인간을 이롭게 한다는 연구가 많습니다.<br>
				하지만 이균은 장에서는 유익균이지만 장을 벗어나는 경우 유해균이 되기도 합니다.<br>
				특히 생식기나 구강에  존재하는 경우 유해성이 있다고 합니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>20</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_entero.png" alt="장유형"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="data_entero">
      <ul class="data_entero_list">
        <li>
          <strong>Bacteroides</strong>
          <em>박테로이데스</em>
          <span>
            <v-chart :options="reportGenus.Bacteroides"  :init-options="{width: 290, height: 180}" />
          </span>
        </li>
        <li>
          <strong>Prevotella</strong>
          <em>프로보텔라</em>
          <span>
            <v-chart :options="reportGenus.Prevotella"  :init-options="{width: 290, height: 180}" />
          </span>
        </li>
        <li>
          <strong>Ruminococcus</strong>
          <em>루미노코커스</em>
          <span>
            <v-chart :options="reportGenus.Ruminococcus"  :init-options="{width: 290, height: 180}" />
          </span>
        </li>
      </ul>
      <div> [ 각 장유형의 나이별 TREND 와 나의 위치 ] </div>
    </div>
    <div class="data_box">
      <div class="txt_area margintop0">
        Entero type은 장기적인 식이취향에 의해 장내 미생물의 우점종이 정해지는 경우입니다.<br><br>
        좋아하는 먹이가 다른 균들이 평소에 숙주가 즐겨 먹는 음식에 의해 번성하는 아주 간단한 원리입니다.<br>
        하지만 가끔 문진에서 답한 식이취향과 다른 결과를 보이는 경우가 있습니다.<br><br>
        이런 경우를 살펴보면 채소를 좋아는 하지만 충분히 먹지 않는 경우 혹은 평소에 먹은 건강기능식품으로 인한 영향 혹은 복용중인 약으로 인하여 장내 환경에 영향을 주는 경우 등이 존재합니다.<br><br>특히 항생제는 다양한 종류가 존재하는데 간혹 항생제의 종류에 따라 민감하게 반응하는 균종들의 경우 농도가 크게 변하기도 합니다.<br><br>
        Phylum분석에서 박테로이데테스(Bacteroidetes)가 마치 아주 좋은 균인 것으로 표현되고 있는데 사실상 반드시 그렇지도 않습니다. <br>박테로이데테스에 중요한 구성인자인 박테로이데스와 프리보텔라는 같은 그룹이지만 먹이는 전혀 다른 미생물입니다.

      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				항생제는 인류의 수명을 연장시켜준 소중한 발명으로서 현대의학에서 가장 중요한 발명 중 하나입니다.<br>
				하지만 장 미생물 역시 ‘균’ 이기 때문에 항생제에 취약할 수밖에 없습니다.<br><br>
				항생제가 노리는 나쁜균과 같이 희생되는 유익균도 존재할 수밖에 없습니다. 항생제를 안쓰는 것이 좋겠지만 써야 한다면 복용 후에는 유익균이 잘 회복될 수 있도록 돌보는 것이 더욱 더 중요합니다.<br><br>따라서 장 환경을 확인하기 위한 장내 미생물 검사는 매우 중요합니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>21</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="cover">
    <div><img src="img/report_information2.jpg"></div>
  </section>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_lactobacilli.png" alt="유산균 분석"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="data_box data_box_graph">
      <h3>Bifidobacterium</h3>
      <div class="graph_area_diversity">
        <v-chart :options="bifiSpeciesChart" :initOptions="{ height: '400px', width: '1038px'}"/>
      </div>
      <h3>Lactobacillus</h3>
      <div class="graph_area_diversity">
        <v-chart :options="lactoSpeciesChart" :initOptions="{ height: '400px', width: '1038px'}"/>
      </div>

      <div class="txt_area">
        대표적인 유산균인 비피더스균과 락토바실러스의 세부종의 분포를 분석합니다.<br><br>
        <span class="mint">
				고객님의 경우 비피더스보다는 락토바실러스가 부족한것으로 보여집니다.<br>
				락토바실러스는 적절한 처방을 통해  다양한 방법으로 섭취할 수 있습니다.
			</span>
      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				대표적 두 가지 유산균인 락토바실러스와 비피더스는 각각 소장과 대장을 책임지는 건강의 파수꾼입니다. 락토바실러스는 <br>면역과 항균효과에 특성이 있으며 비피더스는 대장의 면역 및 장 운동 및 배변에 도움이 된다고 알려져 있습니다.<br>
				이런 유익균은 프로바이오틱스라 칭하고  프로바이오틱스의 먹이를 프리바이오틱스, 프로바이오틱스의 대사산물을 <br> 포스트바이오틱스라고 합니다. 모두 다 자기 역할이 있는 겁니다.<br><br>

				유산균은 장이란 밭에 뿌려지는 씨앗과 같습니다. 좋은 씨앗이 물론 중요하지만 우선 자신한테 잘 맞아야 하고, 또 좋은 비료와 양분이 필요합니다.
				비료와 양분이 되는 것이 프리바이오틱스와 음식입니다.<br>
				그리고 마지막으로 필요한 물과 햇빛은 운동과 생활 습관입니다. 이 중 하나라도 부족하면 씨앗은 그저 씨앗일 뿐 열매가 되지 못하는 이치와 같습니다. 유산균만 먹는다고 장이 좋아지고 변비가 해결되기 어렵습니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>22</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_microbiome.png" alt="당신의 장건강에 미치는 역할"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="gut_part">
      <ul>
        <li class="gut_part_graph">
            <v-chart :options="species.barLineChart.gut" :init-options="{width: 519, height: 398}" theme='shine' />
        </li>
        <li class="gut_part_graph">
            <v-chart :options="species.indexChart.gut" :init-options="{width: 519, height: 398}" theme='shine' />
        </li>
      </ul>
    </div>
    <div class="vuetable">
      <vuetable ref="gut1"
                :fields="species.fields"
                :api-mode="false"
                :per-page=10
                :css="css.table"
                :row-class="species.onRowClass"
                :sortOrder="species.sortOrderForGut"
                :data-manager="dataManager"
      ></vuetable>
    </div>
    <div class="data_box" style="margin-top:20px">
      <div class="txt_area">
        <strong class="gut_title_gut">장 건강</strong>
        장 건강과 관련이 있는 50여종의 균들의 분포를 분석한 결과입니다.<br><br>
        수 많은 장내 미생물 중에서 장에 유익함을 입증하는 연구의 내용에 자주 등장하는 균들입니다. 이 균들은 부티레이트를 생성하거나 염증을 억제하고 혹은 전분이나 지방 등을 분해하는 능력이 있는 균들입니다.<br><br>
        또한 병원체로서의 역할을 하거나 병에 걸린 사람들에게서 공통적으로 많은 균들을 유해균으로 지목하고 있습니다. 하지만 많은 연구에도 불구하고 사람마다 제각기 다른 상황 때문에 이 결과를 질병과 직접적으로 연관 지을 수는 없습니다. 이 검사를 다시 할 경우에 몸의 상태와 세부 균종의 변화를 측정하면 새로운 진단의 방법이 될 수도 있습니다.
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>22</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_microbiome_immunity.png" alt="당신의 면역에 미치는 역할"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="gut_part">
      <ul>
        <li class="gut_part_graph">
          <v-chart :options="species.barLineChart.immunity" :init-options="{width: 519, height: 398}" theme='shine' />
        </li>
        <li class="gut_part_graph">
          <v-chart :options="species.indexChart.immunity" :init-options="{width: 519, height: 398}" theme='shine' />
        </li>
      </ul>
    </div>
    <div class="vuetable">
      <div class="vuetable">
        <vuetable ref="immunity1"
                  :fields="species.fields"
                  :api-mode="false"
                  :per-page=10
                  :css="css.table"
                  :row-class="species.onRowClass"
                  :sortOrder="species.sortOrderForImmunity"
                  :data-manager="dataManager"
        ></vuetable>
      </div>
    </div>
    <div class="data_box">
      <div class="txt_area">
        <strong class="gut_title_immunity">면역</strong>
        장은 인체의 면역 세포의 70%가 존재하는 면역력에 아주 중요한 장기입니다.<br><br>

        장의 면역력에 도움이 되는 균들은 상당히 많은 균들이 장건강과 관련이 있기 때문에 장건강에 도움이 되는 균과 중복되기도 합니다. 대표적인 면역증강균인 아커만시아나 락토바실러스는 주로 소장에서 면역력 증가에 도움을 줍니다.<br><br>

        반대로 면역력을 저하시키는 유해균들은 장벽에서 장세포를 느슨하게 하여 장의 장벽 역할을 저해하고 병원체로서 여러 질병의 원인이 되기도 합니다.
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>24</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <!--
  <div class="header">
    <h2><img src="img/title_microbiome_mellitus.png" alt="당신의 당뇨/비만에 미치는 역할"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="gut_part">
      <ul>
        <li class="gut_part_graph">
          <v-chart :options="species.barLineChart.obesity" :init-options="{width: 519, height: 398}" theme='shine' />
        </li>
        <li class="gut_part_graph">
          <v-chart :options="species.indexChart.obesity" :init-options="{width: 519, height: 398}" theme='shine' />
        </li>
      </ul>
    </div>
    <div class="vuetable">
      <vuetable ref="obesity1"
                :fields="species.fields"
                :api-mode="false"
                :per-page=8
                :css="css.table"
                :row-class="species.onRowClass"
                :sortOrder="species.sortOrderForObesity"
                :data-manager="dataManager"
      ></vuetable>
    </div>
    <div class="data_box">
      <div class="txt_area">
        <strong class="gut_title_diabetes">당뇨/비만</strong>
        연령별로 각 장의 유형을  분류해보면 프리보텔라의 나이별 증가  TREND 가 뚜렷하게 나타납니다.<br>
        나이가 들면서 식이 섬유의 섭취가 증가 되는 한국인의 식생활 패턴이 보여집니다.<br><br>
        장유형은 장기적인 식이 습관이  가장 중요한 요소라고 연구된 결과가 있습니다.<br>
        일시적으로 식사 패턴을 바꾸면 일시적으로 장유형이 식이 에 따라 변화 하지만 원래대로 돌아오면 장패턴 역시 금방 원복 되는 결과 역시 보고되었습니다.<br><br>
        이 패턴이 건강의 절대적인 척도는 아닙니다. 프리보텔라균이 채식인에게 주로 나타나고 , 유익한 단쇄지방산을 생산하지만 박테로이데스에서도 단쇄지방산은 생산이 됩니다.<br><br>
        다만 식이 섬유의 섭취가 작을때는 변비가 생길 수 있고 또한 식이섬유는 다양한 유익균의 먹이가 되기때문에 식이섬유의 섭취정도를 대변하는 프리보텔라의 양이 작을때는 장기적인 관점에서 개선할 필요성을 조언할수 있습니다.<br><br><br>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>26</span></li>
    </ul>
  </div>
  -->
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header blind">
    <h2>별그래프</h2>
  </div>
  <section>
    <div class="star_graph">
      <v-chart :options="starChart" :initOptions="{
            width: 1100, height : 500 }" theme='shine'/>
      </div>
    <div class="tip_box txt_summary">
      <strong>Summary</strong>
      <p v-html="sample.summary"></p>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>28</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="cover">
    <div><img src="img/report_information1_adult.jpg"></div>
  </section>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="full">
  </section>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="full">
    <div class="back_logo"></div>
    <div class="bo_homepage">www.aibiotics.kr</div>
  </section>
  <!-- 페이지끝 -->
</div>
</template>
// striped
<script>
import Vuetable from 'vuetable-2'
import { mapActions } from 'vuex'
import moment from 'moment'
import CssConfig from '../theme/vueTableCssBootstrap4.js'
import FoodTable from './FoodTable'
export default {
  components : {
    Vuetable,
    FoodTable
  },
  name: "report",
  data () {
    return {
      bIndexStr: '',
      bIndexClass: '',
      moment : moment,
      sample: {},
      css: CssConfig,
      balance: {},
      starChart : {},
      species : {},
      survey : {},
      sleep : {},
      heightChartData : {},
      weightChartData : {},
      tastesList : {},
      aibChart1 : {},
      aibChart2 : {},
      byAgeDiversity : {},
      freqDistributionListChart : {},
      phylum : {},
      reportPhylum : {},
      reportGenus : {},
      enterotypePieChart : {},
      balanceGauge : {
        size : {
          width: 400,
          height : 400
        },
        gut : {}
      },
      phylumScatter: {
      },
      bifiSpeciesChart : {},
      lactoSpeciesChart : {},
    }
  },
  methods : {
    ...mapActions(['speciesAction', 'getSample', 'getPhylumChartData', 'lactoDetail', 'getBalanceChartDataForReport']),
    dataManager (s, p) {
      console.log("key " , s[0].key);
      let tableData = {};
      try {
        tableData = this.species.tableData[s[0].key];
      }catch (e){
        return tableData;
      }
      if(s.length > 0){
        //console.log('#report dataManager s', JSON.stringify(s) );
        //console.log('#report dataManager p', JSON.stringify(p) );

        tableData.sort(function(a, b) {
          let bindoA = parseInt(a.bindo);
          let bindoB = parseInt(b.bindo);

          //빈도 수 정렬
          if(s[0].direction === 'desc'){
            return bindoA > bindoB ? -1 : bindoA < bindoB ? 1 : 0;
          }

          return bindoA < bindoB ? -1 : bindoA > bindoB ? 1 : 0;
        });
console.log(p.per_page)
        // 10개 이상이면 Row 제어
        if(p.per_page >= 7) {
          tableData = tableData.slice(0, p.per_page)
        }
      }

      console.log("tableData ", tableData)
      return {
        pagination : p,
        data : tableData
      }
    },
    balanceChart() {
      Object.keys(this.balance).reduce( (acc, key) => {
        this.balance[key].series[0].detail.formatter = " ";
        //balance[key].series[1].detail.formatter = " ";
        if(key === 'totalBalanceGaugeData') this.balance[key].series[1].detail.formatter = " ";
        let centerPos = (key === 'totalBalanceGaugeData')?'60%':'75%';
        this.balance[key].series[0].center[1] = centerPos ;
        this.balance[key].series[1].center[1] = centerPos ;
        delete this.balance[key].toolbox;
      }, {})

      //this.$store.commit("setBalance", balance);
      console.log("#report ")
      console.log(this.balance)
    },
    async getReportSample() {
      await this.getSample();
      this.sample = this.$store.state.sample;
    },
    async getBalanceIndex(){
      let len = this.starChart.adjustedStarScore.length;
      let bIndex = this.starChart.adjustedStarScore.reduce( (acc, obj, index) => {
        acc = acc + obj;
        if(len === (index) + 1) return acc / len;
        return acc;
      }, 0);

      console.log("get bindex !!! ", this.starChart.adjustedStarScore);
      console.log("get bindex !!! ", bIndex);

      switch(true){
        case (bIndex > 8.5):
          this.bIndexStr = "우수";
          this.bIndexClass = "score_a"
          break;
        case (bIndex > 6.5 && bIndex < 8.5):
          this.bIndexStr = "양호";
          this.bIndexClass = "score_b"
          break;
        case (bIndex > 4.5 && bIndex < 6.5):
          this.bIndexStr = "주의";
          this.bIndexClass = "score_c"
          break;
        case (bIndex > 3 && bIndex < 3.5):
          this.bIndexStr = "경고";
          this.bIndexClass = "score_d"
          break;
        default:
          this.bIndexStr = "위험";
          this.bIndexClass = "score_e"
          break;
      }

    },
    getReportData() {
      this.getBalanceChartDataForReport().then( balance => {
        this.balance = balance;
      })
      this.starChart = this.$store.state.starChart.bgChart;
      this.species = this.$store.state.species;
      this.survey = this.$store.state.survey;
      this.sleep = this.$store.state.sleepChartData;
      this.heightChartData = this.$store.state.heightChartData;
      this.weightChartData = this.$store.state.weightChartData;
      this.tastesList = this.$store.state.tastesList;
      this.aibChart1 = this.$store.state.aibChart1;
      this.aibChart2 = this.$store.state.aibChart2;
      this.byAgeDiversity = this.$store.state.byAgeDiversity;
      this.freqDistributionListChart = this.$store.state.freqDistributionListChart;
      this.phylum = this.$store.state.phylum;
      this.enterotypePieChart = this.$store.state.enterotypePieChart;
    },
    //species tables
    reloadTable(){
      try{
        Object.keys(this.species.tableData).forEach( key => {
          this.$refs[(key+1)].reload();
          this.$refs[(key+2)].reload();
        })
      }catch (e){
        console.log(e)
        return;
      }
    }
},
  async mounted () {
    console.log('#report mounted')
    this.getReportData();

    //this.balanceChart();
    await this.getReportSample();
    await this.getBalanceIndex();
    //await this.speciesAction(); //home > species 에서 호출
    this.$store.state.focusPageNm = "report";
    //나이별 다양성
    await this.getPhylumChartData("phylum");
    await this.getPhylumChartData("genus");
    this.reportPhylum = this.$store.state.reportPhylum;
    this.reportGenus = this.$store.state.reportGenus;

    //유산균
    let lactoDetailData = await this.lactoDetail();
    this.bifiSpeciesChart = lactoDetailData.speciesDetail.bifi;
    this.lactoSpeciesChart = lactoDetailData.speciesDetail.lacto;

    this.reloadTable();
  },
  created () {
  },
  updated () {
    this.$nextTick(function() {
      let divPage = document.querySelectorAll( 'div.footer>ul>li>span' );
      for ( let i = 0; i < divPage.length; i++ ) {
        divPage[i].innerText = i + 1;
      }
    });
  },
  computed : {
    aibTotal(){
      return this.$store.state.aibTotal;
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../theme/reportScss';
</style>